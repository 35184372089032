<template>
  <dir id="demandMarketAdmin">
    <h2>需求市场标签管理</h2>
    <div class="dma-body">
      <!-- 筛选条件 -->
      <div class="dma-condition">
        <div class="dma-condition-ss">
          <el-input v-model="searchValue" placeholder="请输入搜索内容"></el-input>
          <el-button type="primary" icon="el-icon-search" :loading="searchLoading" @click="pageChange(1)" style="margin-left: 10px">搜索</el-button>         
        </div>
      </div>
      <!-- 表格 -->
      <div class="dma-table-box">
        <!-- 表格主体 -->
        <div class="dma-table">
          <el-table :data="tableData" :span-method="arraySpanMethod" style="width: 100%" border>
            <el-table-column prop="firstDeman" label="一级分类" align="center"></el-table-column>
            <el-table-column prop="secondDeman" label="二级分类" align="center"></el-table-column>
            <el-table-column prop="thirdDeman" label="三级分类" align="center"></el-table-column>
            <el-table-column fixed="right" label="操作" align="center" width="225">
              <template slot-scope="scope">
                <el-button v-if="scope.row.firstID != 'add'" @click="thisRowData = scope.row;editVisible = true" plain class="btn-bg-weight" size="small">编辑</el-button>
                <el-button v-if="scope.row.firstID != 'add'" @click="delClick(scope.row, scope.$index)" plain type="warning" class="btn-bg-weight" size="small">删除</el-button>
                <el-button @click="addClick(scope.row)" plain type="primary" class="btn-bg-weight" size="small">新增</el-button>
              </template>            
            </el-table-column>
          </el-table>
        </div>
        <Paging
          style="text-align: center;padding-top: 20px;"
          @currentPageChange="pageChange"
          :pageSize="page.per_page"
          :total="page.total"
          :page="page.current_page"
        />
      </div>
    </div>
    <!-- 编辑弹窗 -->
    <el-dialog title="编辑分类" :visible.sync="editVisible">
      <el-form ref="form" :model="thisRowData" label-width="80px">
        <el-form-item label="一级分类">
          <el-input v-model="thisRowData.firstDeman"></el-input>
        </el-form-item>
        <el-form-item label="二级分类">
          <el-input v-model="thisRowData.secondDeman"></el-input>
        </el-form-item>
        <el-form-item label="三级分类">
          <el-input v-model="thisRowData.thirdDeman"></el-input>
        </el-form-item>                
        <el-form-item>
          <el-button type="primary" @click="onSubmit" :loading="editLoading">确定</el-button>
        </el-form-item>
      </el-form>      
    </el-dialog>
    <!-- 添加弹窗 -->
    <el-dialog title="添加分类" :visible.sync="addVisible" width="50%">
      <div class="dm-addDemand-box">
        <el-select
          v-model="value1"
          @change="firstChange"
          filterable allow-create default-first-option
          placeholder="请输入需要添加的一级类别">
          <el-option
            v-for="item in firstOptions"
            :key="item.id"
            :label="item.title"
            :value="item.id">
          </el-option>
        </el-select>
        <el-select
          v-model="value2"
          @change="secondChange"
          filterable allow-create default-first-option
          placeholder="请输入需要添加的二级类别">
          <el-option
            v-for="item in secondOptions"
            :key="item.id"
            :label="item.title"
            :value="item.id">
          </el-option>
        </el-select>
        <el-select
          v-model="value3"
          @change="thirdChange"
          filterable allow-create default-first-option
          placeholder="请输入需要添加的三级类别">
          <el-option
            v-for="item in thirdOptions"
            :key="item.id"
            :label="item.title"
            :value="item.id">
          </el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addVisible = false">取消</el-button>
        <el-button type="primary" :loading="addLoading" @click="addDemand" class="btn-bg-color btn-bg-hover-color btn-border-color">提交</el-button>
      </span>
    </el-dialog>     
  </dir>  
</template>
<script>
import Paging from "@/components/PageComponents";

export default {
  name: "demandMarketAdmin",
  data(){
    return {
      searchValue: '',
      searchLoading: false, // 搜索loading
      time1: '', //定时器
      res: [], // 假数据
      newsNum: 0, //新消息
      addData: [
        {
          "id": "add",
          "title": "",
          "children": [
              {
                  "title": "",
                  "children": [
                      {
                          "title": "",
                      }
                  ]
              }
          ]
        }
      ],
      tableData: [
        {
          "title": "",
          "children": [
              {
                  "title": "",
                  "children": [
                      {
                          "title": "",
                      }
                  ]
              }
          ]
        }        
      ], // 表格渲染需要的数据      
      typeNameArr: [], // 第一列进行合并操作时存放的数组变量
      typeNamePos: 0, // 上面的数组的下标值
      storeArr: [], // 第二列进行合并操作时存放的数组变量
      storePos: 0, // 上面的数组的下标值
      page: {}, // 分页
      currentPage: 1, // 当前页
      editVisible: false, //弹窗编辑
      editLoading: false, //编辑loading
      value1: '',
      value2: '',
      value3: '',
      firstOptions: [], //新增一级
      secondOptions: [],//新增二级
      thirdOptions: [],//新增三级      
      addVisible: false, //弹窗新增
      addLoading: false, // 新增loading
      thisRowData: [], //编辑用该条数据
    }
  },
  created() {
    this.getTableData()
  },
  beforeDestroy() {
    clearInterval(this.time1);
    this.time1 = null;
  },
  components: {
    Paging
  },  
  methods: {
    //获取表格数据
    getTableData() {
      this.searchLoading = true
      this.tableData = [] //清除表格数据
      this.$superHttp.get(this.URL.superUrl.demandListSuper, {
        params: {
          keyword: this.searchValue,
          }
        })
        .then(res => {
          let data = res.data.data
          this.firstOptions = data
          data.length == 0 ?this.resFormat ([this.addData[this.currentPage - 1]]) : this.resFormat ([data[this.currentPage - 1]]) //格式化获取的数据
          this.page = {
            per_page: 1,
            total: data.length,
            current_page: this.currentPage,
          }
          this.searchLoading = false
        })
        .catch(err => {
          this.searchLoading = false
          console.log(err)
        })
    },
    // 数据格式化
    resFormat(data) {
      let tData = JSON.parse(JSON.stringify(data))
      for(let f in tData) {
        for(let s in tData[f].children) {
          for(let t in tData[f].children[s].children) {
            this.tableData.push({
              firstDeman: tData[f].title,
              firstID: tData[f].id,
              secondDeman: tData[f].children[s].title, 
              secondID: tData[f].children[s].id, 
              thirdDeman: tData[f].children[s].children[t].title,
              thirdID: tData[f].children[s].children[t].id 
            })
          }
        }
      }
      this.merage()
    },
    // 合并数据处理
    merage() {
      // 合并数据初始化
      this.typeNameArr = [];
      this.typeNamePos = 0;
      this.storeArr = [];
      this.storePos = 0;
      // 合并数据结构拼接
      for (let i = 0; i < this.tableData.length; i += 1) {
        if (i === 0) {
          // 第一行必须存在
          this.typeNameArr.push(1);
          this.typeNamePos = 0;
          this.storeArr.push(1);
          this.storePos = 0;
        } else {
          // 第一列
          if (this.tableData[i].firstDeman === this.tableData[i - 1].firstDeman) {
            this.typeNameArr[this.typeNamePos] += 1;
            this.typeNameArr.push(0);
          } else {
            this.typeNameArr.push(1);
            this.typeNamePos = i;
          }
          // 第二列
          if (this.tableData[i].secondDeman === this.tableData[i - 1].secondDeman && this.tableData[i].firstDeman === this.tableData[i - 1].firstDeman) {
            this.storeArr[this.storePos] += 1;
            this.storeArr.push(0);
          } else {
            this.storeArr.push(1);
            this.storePos = i;
          }
        }
      }
    },
    // 表格数据渲染
    arraySpanMethod ({row, column, rowIndex, columnIndex}){
      if (columnIndex === 0) {
        // 第一列的合并方法
        const row1 = this.typeNameArr[rowIndex];
        const col1 = row1 > 0 ? 1 : 0; 
        return {
          rowspan: row1,
          colspan: col1,
        };
      } else if (columnIndex === 1) {
        // 第二列的合并方法
        const row2 = this.storeArr[rowIndex];
        const col2 = row2 > 0 ? 1 : 0;
        return {
          rowspan: row2,
          colspan: col2,
        };
      }
    },
    // 添加按钮
    addClick(row) {
      this.addVisible = true
      if(row.firstID != "add"){
        this.value1 = row.firstID
        this.firstChange(this.value1)
        this.value2 = row.secondID
        this.secondChange(this.value2)
        this.thirdChange(this.value3)
      }
    },
    // 添加一级changge
    firstChange(val) {
      this.value2 = ''
      this.secondID = ''
      this.secondTitle = ''
      this.secondOptions = []
      this.value3 = ''
      this.thirdID = ''
      this.thirdTitle = ''
      this.thirdOptions = []
      let isNew = true
      this.firstOptions.find(item => {
        if(item.id === val){
          this.secondOptions = item.children
          this.firstTitle = item.title
          this.firstID = val
          isNew = false
        }
      })
      if(isNew){
        this.firstID = 0
        this.firstTitle = this.value1
      }
    },
    // 添加二级change
    secondChange(val) {
      this.value3 = ''
      this.thirdID = ''
      this.thirdTitle = ''
      this.thirdOptions = []
      let isNew = true
      this.secondOptions.find(item => {
        if(item.id === val){
          this.thirdOptions = item.children
          this.secondTitle = item.title
          this.secondID = val
          isNew = false
        }
      })
      if(isNew){
        this.secondID = 0
        this.secondTitle = this.value2
      }      
    },
    // 添加三级change
    thirdChange(val) {
      let isNew = true
      this.thirdOptions.find(item => {
        if(item.id === val){
          this.thirdTitle = item.title
          this.thirdID = val
          isNew = false          
        }
      })
      if(isNew){
        this.thirdID = 0
        this.thirdTitle = this.value3
      }      
    },
    // 添加新类别
    addDemand() {
      if(this.firstID === ''){
        this.$message.error('请选择一级类别');
      }else if(this.secondID === ''){
        this.$message.error('请选择二级类别');
      }else if(this.thirdID === ''){
        this.$message.error('请选择三级类别');
      }else if(this.firstID != 0 && this.secondID != 0 && this.thirdID !=0){
        this.$message.error('请填写新类别');
      }else{
        if(this.firstTitle.length>8 || this.secondTitle.length>8 || this.thirdTitle.length>8){
          this.$message.error('新增类别名最多可输入8个');
        }else{
          let obj = {
            app_id: this.appId,
            types: [{
              "first":{ "id": this.firstID,"title": this.firstTitle},
              "second":{ "id": this.secondID,"title": this.secondTitle},
              "third":{ "id": this.thirdID,"title": this.thirdTitle}
            }]
          }
          this.addLoading = true
          this.$superHttp.
            post(this.URL.superUrl.demandTypeAddSuper, obj)
            .then(res => {
              this.getTableData()
              this.addLoading = false
              this.addVisible = false
              this.$message.success('添加成功');
            }).catch(err => {
              this.addLoading = false
              this.$message.error(err);
            })
        }
      }
    },    
    // 删除当条目
    delClick(row) {
      this.$confirm('是否删除分类名为“' + row.thirdDeman + '”的分类', '删除需求分类', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
      this.$superHttp
        .delete(this.URL.superUrl.demandTypeInfoSuper + row.thirdID)
        .then(res => {
          this.$message.success('删除成功!');
          this.getTableData()
        })
        .catch(err => {
          console.log(err)
        })          
      }).catch(() => {
        this.$message({type: 'info',message: '已取消删除'});
      });
    },
    // 提交编辑
    onSubmit() {
      if(!this.thisRowData.firstDeman) {
        this.$message.error('请填写一级类别');
      }else if(!this.thisRowData.secondDeman) {
        this.$message.error('请填写二级类别');
      }else if(!this.thisRowData.thirdDeman) {
        this.$message.error('请填写三级类别');
      }else{
        this.editLoading = true
        let obj = {
          first: {id: this.thisRowData.firstID, title: this.thisRowData.firstDeman},
          second: {id: this.thisRowData.secondID, title: this.thisRowData.secondDeman},
          third: {id: this.thisRowData.thirdID, title: this.thisRowData.thirdDeman},
        }
        this.$superHttp
          .put(this.URL.superUrl.demandTypeInfoSuper, obj)
          .then(res => {
            this.editLoading = false
            this.$message.success('编辑成功');
            this.editVisible = false
            this.getTableData()
          })
          .catch(err => {
            this.editLoading = false
            console.log(err)
          })
      }
    },
    // 页面跳转获取页面
    pageChange(p) {
      this.currentPage = p
      this.getTableData()
    },
  },
}
</script>
<style lang="less" scoped>
  #demandMarketAdmin {
    padding: 0px;
    margin: 0px;
    height: 100%;
    // 筛选
    .dma-condition-ss{
      box-sizing: border-box;
      position: relative;
      margin-top: 20px;
      .el-input {
        width: 70%;
      }
      .el-badge {
        position: absolute;
        right: 17px;
      }
    }
    // 表格搜索
    .dma-table-box {
      width: 100%;
      border-radius: 10px;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      margin-top: 30px;
      .dma-table {
        margin-bottom: 10px;
      }
      .el-pagination {
        text-align: center;
      }
    }
    // 弹出层
    .dm-addDemand-box{
      width: 100%;
      .el-select {
        width: 32%;
        margin: 0 0.5%;
      }
    }    
  }
</style>